import updateLocation2 from './location2';
import { isWebZip } from './webzip';

const pf = navigator.platform;
const ua = navigator.userAgent;
const { href } = window.location2;
const locSearch = window.location2.search;
const utility = {
  // 浅复制
  copy(obj) {
    // 基本类型和函数直接返回
    if (!(obj instanceof Object) || typeof obj === 'function') {
      return obj;
    }
    const newObj = {};
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        newObj[p] = obj[p];
      }
    }
    return newObj;
  },
  versionCompare(ver1, ver2) {
    // 版本号有返回为空的情况
    if (ver1 === null) {
      return false;
    }
    const version1pre = parseFloat(ver1);
    const version2pre = parseFloat(ver2);
    const version1next = ver1.replace(`${version1pre}.`, '');
    const version2next = ver2.replace(`${version2pre}.`, '');
    if (version1pre > version2pre) {
      return true;
    } if (version1pre < version2pre) {
      return false;
    } if (version1next >= version2next) {
      return true;
    }
    return false;
  },
  createUUID(isClient) {
    const s = [];
    const hexDigits = '0123456789abcdef';
    let uuid;
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    if (isClient) {
      uuid = s.join('').substr(0, 32);
    } else {
      s[8] = s[13] = s[18] = s[23] = '-';
      uuid = s.join('');
    }
    return uuid;
  },
  buildQuery(obj) {
    const arr = [];
    if (typeof obj === 'object') {
      for (const i in obj) {
        if (obj.hasOwnProperty(i)) {
          arr.push(
            `${i}=${encodeURIComponent(obj[i] == null ? '' : `${obj[i]}`)}`
          );
        }
      }
    }
    return arr.join('&');
  },
  create(url) {
    let img = new Image(0, 0);
    img.onload = function () {
      img = null;
    };
    img.src = url;
    if (img.complete) {
      img = null;
    }
  },
  isAndroid() {
    const IsAndroid = /Android|HTC/i.test(ua);
    return IsAndroid;
  },
  isIos() {
    const IsAndroid = /Android|HTC/i.test(ua);
    const IsIPhone = !IsAndroid && /iPod|iPhone/i.test(ua);
    return IsIPhone;
  },
  isMobile() {
    const IsAndroid = /Android|HTC/i.test(ua);
    const IsIPhone = !IsAndroid && /iPod|iPhone/i.test(ua);
    return IsAndroid || IsIPhone;
  },
  getQueryString(name) {
    const url = window.location2.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) { return null }
    if (!results[2]) { return '' }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  getCookie(name) {
    let ret;
    let arr;
    let i;
    let len;
    if (document.cookie) {
      arr = document.cookie.split('; ');
      for (i = 0, len = arr.length; i < len; i++) {
        if (arr[i].indexOf(`${name}=`) === 0) {
          ret = decodeURIComponent(arr[i].substr(name.length + 1));
          break;
        }
      }
    }
    return ret;
  },
  /*
  *@desc set cookie
  *@desc options  obj
  {
   expires:'',
   path:'',
   secure:'',
   secure:''
  }
  *
  */
  setCookie(name, value, optionsPramas) {
    const arr = [];
    let date;
    const options = optionsPramas || {};
    if (value == null) {
      value = '';
      options.expires = -1;
    }
    if (typeof options.expires === 'number') {
      date = new Date();
      date.setTime(date.getTime() + options.expires * 1000 * 60 * 60 * 24);
    } else if (options.expires instanceof Date) {
      date = options.expires;
    }
    arr.push(`${name}=${encodeURIComponent(value)}`);
    date && arr.push(`expires=${date.toUTCString()}`);
    options.path && arr.push(`path=${options.path}`);
    options.domain && arr.push(`domain=${options.domain}`);
    options.secure && arr.push('secure');
    return (document.cookie = arr.join('; '));
  }
};

/**
   * @description: 参数序列化
   * @param { Object } obj
   * @return 'id=123&name=sdf'
   */
const json2url = (obj = {}) => {
  const str = [];
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
  });
  return str.join('&');
};
const WinMap = {
  'windows nt 5.0': 1,
  'windows 2000': 2,
  'windows nt 5.1': 3,
  'windows xp': 4,
  'windows nt 5.2': 5,
  'windows 2003': 6,
  'windows nt 6.0': 7,
  'windows vista': 8,
  'windows nt 6.1': 9,
  'windows 7': 10,
  'windows nt 6.2': 11,
  'windows 8': 12,
  'windows nt 6.3': 13,
  'windows 8.1': 14,
  'windows nt 6.4': 23,
  'windows 10': 24,
  'windows nt 10.0': 25
};
const sysMap = {
  win32: WinMap,
  windows: WinMap,
  android: 15,
  ipad: 16,
  iphone: 17,
  macintosh: 18,
  macIntel: 19,
  mac: 20,
  x11: 21,
  linux: 22
};

// 获取完整地址
const getFullUrl = (url) => {
  // 排除远程资源和离线包内的依赖
  if (/^https|http|\/\//g.test(url) || (isWebZip && /^\.\/resource\//.test(url))) {
    return url;
  }
  const { origin } = window.location2;
  if (url.charAt(0) === '/') {
    return `${origin}${url}`;
  }
  return `${origin}/${url}`;
};

// 加载js
const loadJs = (url, isAsync) => {
  const src = getFullUrl(url);
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState == 'loaded' || script.readyState == 'complete') {
          script.onreadystatechange = null;
          resolve(src);
        }
      };
    } else {
      script.onload = () => { resolve(src) };
      script.onerror = (e) => { console.log('onerror', e); reject(e) };
    }
    script.src = src;
    (!!isAsync) && (script.async = 1);
    (document.body || document.getElementsByTagName('head').item(0)).appendChild(script);
  });
};

const transformRequest = d => Object.keys(d).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(d[k])}`).join('&');

const getUserInfo = () => new Promise((resolve, reject) => {
  if (window.KEWLApp && window.KEWLApp.isKEWLApp) {
    window.KEWLApp.getUserInfo((data) => {
      const userInfo = typeof data === 'string' && data !== 'null' && data !== ''
        ? JSON.parse(data)
        : data;
      if (userInfo instanceof Object && userInfo.userId && userInfo.token && userInfo.deviceId) {
        resolve(userInfo);
      } else {
        reject();
      }
    });
  } else {
    // eslint-disable-next-line no-nested-ternary
    const userInfo = window.localStorage ? (window.localStorage.getItem('userInfo') ? JSON.parse(window.Base64.fromBase64(window.localStorage.getItem('userInfo'))) : '') : '';
    if (userInfo instanceof Object && userInfo.user && userInfo.user.uid) {
      resolve(userInfo);
    } else {
      reject();
    }
  }
});

window.KEWLUtils = {
  getFullUrl,
  loadJs
};

export {
  utility, WinMap, sysMap, json2url, getFullUrl, loadJs, transformRequest, getUserInfo
};
