import updateLocation2 from './location2';

const KEWLWebZip = window.KEWLWebZip || {};
export const isWebZip = ['content:', 'file:'].includes(window.location.protocol);
KEWLWebZip.isWebZip = isWebZip;

KEWLWebZip.jump = ({ url, isReplace }) => {
  let href = url;
  if (window.KEWLWebZip.isWebZip) {
    // 同一个离线包内的页面
    if (new RegExp(`/app/${window.KEWLWebZip.name}`).test(url)) {
      href = window.location.pathname.replace(/(\/dist\/.*)|\/[^/]+(?!.)/g, url.split(`/app/${window.KEWLWebZip.name}`)[1]);
      // 追加WEBHOST参数
      if (window.KEWLWebZip.WEBHOST && href.indexOf('WEBHOST') == -1) {
        href += `${href.indexOf('?') > -1 ? '&' : '?'}WEBHOST=${encodeURIComponent(window.KEWLWebZip.WEBHOST)}`;
      }
    } else if (/^\/\//g.test(url)) {
      // 以//开头
      href = `${window.location2.protocol}${url}`;
    } else if (/^\//g.test(url)) {
      // 以/开头
      href = `${window.location2.origin}${url}`;
    } else if (/https:\/\/|http:\/\/|^\/\//g.test(url)) {
      // http或者https协议链接
      href = url;
    }
  }
  if (isReplace) {
    window.location.replace(href);
  } else {
    window.location.href = href;
  }
};

let zipVersions;

const fetchZipVersion = () => new Promise((resolve) => {
  const t = new Date().getTime();
  window.KEWLHttp && window.KEWLHttp.get(`${window.location2.origin}/app/webzip/zipVersion.json?t=${t}`).then((res) => {
    if (res.status == 200) {
      if (res.data.zips) {
        zipVersions = res.data.zips;
        resolve(zipVersions);
      }
    }
  });
});

const checkZipVersion = (name, version, callback) => {
  if (zipVersions) {
    callback(name, version, zipVersions[name]);
  } else {
    fetchZipVersion().then(() => {
      callback(name, version, zipVersions[name]);
    });
  }
};

const findSelfZip = () => {
  let prex;
  let name;
  let version;
  if (window.KEWLWebZip.isWebZip) {
    ({ prex, name, version } = window.KEWLWebZip);
    if (!prex) {
      prex = `/app/${name}`;
    }
  } else {
    const result = /\/app\/(.*?)\//g.exec(window.location2.href);
    if (result && Array.isArray(result) && result.length > 1) {
      [prex, name] = result;
    } else {
      // 不符合结构，一般出现在本地开发时
      return;
    }
    version = 0;
  }
  checkZipVersion(name, version, (_, ver, latestVer) => {
    if (!latestVer && window.KEWLWebZip.isWebZip) {
      // 移除zip包
      window.KEWLApp.deleteWebZipRes(prex);
      // 替换为远程地址，展示最新内容
      window.location.replace(window.location2.href);
    } else if (latestVer > ver) {
      // 通知客户端下载最新zip包
      window.KEWLApp.downloadWebZipRes(name, latestVer);
      if (window.KEWLWebZip.isWebZip) {
        // 替换为远程地址，展示最新内容
        window.location.replace(window.location2.href);
      }
    }
  });
};

KEWLWebZip.findSelfZip = findSelfZip;
KEWLWebZip.fetchZipVersion = fetchZipVersion;
KEWLWebZip.checkZipVersion = checkZipVersion;

window.KEWLWebZip = KEWLWebZip;

export default KEWLWebZip;
