/*
 * 用于根据域名使用接口域名
 * 薛荣 （0805）
 * 修改包使用的域名或多包新增要处理 是否是线上
*/
// import updateLocation2 from './location2';
// eslint-disable-next-line max-len
// const domain = 'liveme.com,www.liveme.com,www.royallive.com,www.aaalive.com,sync.liveme.com,www.arablive.online,twoo.liveme.com,www.lmlite.net,www.cheez.com,meet.linkv.sg,pro.liveme.com,www.poplive.live,www.frill.live,www.frilllive.com,www.lekerberos.com,pro.lekerberos.com,pop.lekerberos.com,royal.lekerberos.com,web.linkv.sg,www.coinsgames.net,www.shanghaileyu.com,www.photogrid.app,liveme.bi.ksmobile.net,www.livemeet.tv,www.oedipus123.com,pro.oedipus123.com,www.muses123.com,pro.muses123.com';
// const host = document.location2.host || document.location2.hostname;
// eslint-disable-next-line import/no-mutable-exports
// let ISONLINE;
// if (domain.indexOf(host) >= 0) {
//   ISONLINE = true;
// } else {
//   ISONLINE = false;
// }
// window.ISONLINE = ISONLINE;
// 为减少维护成本， master 分支代码直接处理为 true，直接使用生产接口； 切勿随意修改
const ISONLINE = true;
window.ISONLINE = ISONLINE;

export default ISONLINE;
