function getQueryString(name) {
  const url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) { return null }
  if (!results[2]) { return '' }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const WEBHOST = getQueryString('WEBHOST');
if (window.KEWLWebZip) {
  window.KEWLWebZip.WEBHOST = WEBHOST;
}

function updateLocation2() {
  let newURL;
  if (window.KEWLWebZip && window.KEWLWebZip.remotePath && WEBHOST) {
    try {
      const match = /([^/]+\.[^/]+)$/.exec(window.location.pathname);
      const fileName = match ? match[1] : 'index.html';
      const tail = window.location.href.split(fileName)[1];
      newURL = new URL(`${WEBHOST}${window.KEWLWebZip.remotePath}/${fileName}${tail}`);
    } catch (error) {
      window.KEWLError.report({
        errorMsg: `离线包内location2.js new URL失败：${error.message}`,
        target: window.location.href,
        errorType: 0
      });
    }
  }
  if (!newURL) {
    newURL = new URL(window.location.toString());
  }
  // 跳转需要使用window.KEWLWebZip.jump
  const location2 = {
    toString() {
      return newURL.toString();
    },
    reload() {
      window.location.reload();
    },
    assign(url) {
      window.KEWLWebZip.jump({ url });
    },
    replace(url) {
      window.KEWLWebZip.jump({ url, isReplace: true });
    },
    get href() {
      return newURL.href || window.location.href;
    },
    set href(value) {
      window.KEWLWebZip.jump({ url: value });
    }
  };
  // copy剩余属性和方法
  Object.keys(window.location).forEach((key) => {
    !location2[key] && (location2[key] = newURL[key] || window.location[key]);
  });

  const obj = {
    configurable: true,
    get() {
      return location2;
    },
    set(value) {
      if (typeof value === 'string') {
        window.KEWLWebZip.jump({ url: value });
      }
    }
  };
  Object.defineProperty(window, 'location2', obj);
  Object.defineProperty(document, 'location2', obj);
  return location2;
}
updateLocation2();

let prevHref = window.location.href;
function urlChange(e) {
  setTimeout(() => {
    console.log('change!', window.location.href, e);
    if (prevHref !== window.location.href) {
      prevHref = window.location.href;
      updateLocation2();
    }
  });
}

// 监听url变化
(function (history) {
  const { pushState } = history;
  history.pushState = function (...args) {
    if (typeof history.onpushstate === 'function') {
      history.onpushstate({ args });
    }
    return pushState.apply(history, args);
  };
  const { replaceState } = history;
  history.replaceState = function (...args) {
    if (typeof history.onreplacestate === 'function') {
      history.onreplacestate({ args });
    }
    return replaceState.apply(history, args);
  };
}(window.history));

window.onhashchange = urlChange;
window.onpopstate = urlChange;
window.history.onpushstate = urlChange;
window.history.onreplacestate = urlChange;

export default updateLocation2;
